.compact-editor :global(.public-DraftEditor-content) {
  @apply min-h-11;
}

.editor :global(.public-DraftEditor-content) {
  @apply min-h-52;
}

.text-align-center :global(.public-DraftStyleDefault-ltr) {
  @apply text-center leading-6;
}

.text-align-left :global(.public-DraftStyleDefault-ltr) {
  @apply text-left leading-6;
}

.text-align-right :global(.public-DraftStyleDefault-ltr) {
  @apply text-right leading-6;
}
