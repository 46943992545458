.dropdown-container {
  @apply overflow-hidden absolute inset-x-0 mt-2 max-w-xs bg-white rounded-md shadow-lg;
}

.dropdown-item {
  @apply flex gap-1 justify-between items-center py-3 px-4 cursor-pointer;
}

.dropdown-item-selected {
  @apply bg-amplify-green-25;
}

.dropdown-item-unselected {
  @apply hover:bg-gray-50;
}

.dropdown-container-max {
  @apply overflow-hidden absolute inset-x-0 mt-2 md:max-w-xs sm:max-w-full bg-white rounded-md shadow-lg;
}
