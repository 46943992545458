.base {
  @apply inline-flex gap-2 justify-center items-center font-sans font-medium tracking-normal whitespace-nowrap outline-none transition duration-200 ease-linear;
}

.primary {
  @apply text-white rounded-lg border hover:shadow-sm active:shadow-sm bg-amplify-green-700 border-amplify-green-700 shadow-xs hover:bg-amplify-green-900 hover:border-amplify-green-800 active:bg-amplify-green-900 active:border-amplify-green-800 focus:bg-amplify-green-700 focus:border-amplify-green-700 disabled:bg-amplify-green-500 disabled:border-amplify-green-500 disabled:shadow-xs;
}

.primary:focus {
  box-shadow: 0px 1px 2px rgba(17, 25, 39, 0.05), 0px 0px 0px 1px #dafcf2;
}

.primary-destructive {
  @apply text-white bg-red-600 hover:bg-red-700 focus:bg-red-600 active:bg-red-800 disabled:bg-red-200 rounded-lg border border-red-600 hover:border-red-700 focus:border-red-600 active:border-red-800 disabled:border-red-200 hover:shadow-sm active:shadow-sm shadow-xs disabled:shadow-xs;
}

.primary-destructive:focus {
  box-shadow: 0px 1px 2px rgba(17, 25, 39, 0.05), 0px 0px 0px 1px #fee4e2;
}

.ai-button {
  border: none;
  position: relative;
  @apply rounded-lg;
}

.ai-button::before {
  content: '';
  position: absolute;
  inset: 0;
  @apply rounded-lg;
  padding: 1.5px;
  background: linear-gradient(136.78deg, #8325ff 6.64%, #7fc6f4 84.58%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  pointer-events: none;
}

.secondary-gray {
  @apply text-gray-700 hover:text-gray-900 active:text-gray-900 disabled:text-gray-300 bg-white hover:bg-gray-50 focus:bg-white active:bg-gray-100 disabled:bg-white rounded-lg border border-gray-300 hover:border-gray-300 focus:border-gray-300 active:border-gray-300 disabled:border-gray-200 hover:shadow-sm active:shadow-sm disabled:shadow-xs shadow-xs;
}

.secondary-gray:focus {
  box-shadow: 0px 1px 2px rgba(17, 25, 39, 0.05), 0px 0px 0px 1px #f3f4f6;
}

.secondary-gray-destructive {
  @apply text-red-700 hover:text-red-800 active:text-red-800 disabled:text-red-300 bg-white hover:bg-red-50 focus:bg-white active:bg-red-100 disabled:bg-white rounded-lg border border-red-300 hover:border-red-300 focus:border-red-300 active:border-red-300 disabled:border-red-200 hover:shadow-sm active:shadow-sm disabled:shadow-xs shadow-xs;
}

.secondary-gray-destructive:focus {
  box-shadow: 0px 1px 2px rgba(17, 25, 39, 0.05), 0px 0px 0px 1px #fee4e2;
}

.secondary-neon {
  @apply disabled:text-gray-300 disabled:bg-gray-25 rounded-lg border disabled:border-gray-25 text-fresh-neon-900 bg-fresh-neon-25 hover:bg-fresh-neon-50 focus:bg-fresh-neon-25 active:bg-amplify-green-50 border-fresh-neon-25 hover:border-fresh-neon-50 focus:border-fresh-neon-25 active:border-amplify-green-50 shadow-xs;
}

.secondary-neon:focus {
  box-shadow: 0px 1px 2px rgba(17, 25, 39, 0.05),
    0px 0px 0px 1px rgba(160, 255, 227, 0.35);
}

.secondary-neon-destructive {
  @apply text-red-700 hover:text-red-800 active:text-red-800 disabled:text-red-300 bg-red-50 hover:bg-red-100 focus:bg-red-50 active:bg-red-200 disabled:bg-red-25 rounded-lg border border-red-50 hover:border-red-100 focus:border-red-50 active:border-red-100 disabled:border-red-25 shadow-xs;
}

.secondary-neon-destructive:focus {
  box-shadow: 0px 1px 2px rgba(17, 25, 39, 0.05), 0px 0px 0px 1px #fee4e2;
}

.tertiary-gray {
  @apply text-gray-700 hover:text-gray-900 active:text-gray-900 disabled:text-gray-300 hover:bg-gray-50 active:bg-gray-100 rounded-lg;
}

.tertiary-gray:focus {
  box-shadow: 0px 1px 2px rgba(17, 25, 39, 0.05), 0px 0px 0px 1px #f3f4f6;
}

.tertiary-color {
  @apply text-amplify-green-700 hover:text-amplify-green-800 active:text-amplify-green-800 disabled:text-gray-300 hover:bg-amplify-green-50 active:bg-amplify-green-100 rounded-lg;
}

.tertiary-color:focus {
  box-shadow: 0px 1px 2px rgba(17, 25, 39, 0.05), 0px 0px 0px 1px #f3f4f6;
}

.tertiary-destructive {
  @apply text-red-700 hover:text-red-800 active:text-red-800 disabled:text-red-300 hover:bg-red-50 active:bg-red-100 rounded-lg;
}

.tertiary-destructive:focus {
  box-shadow: 0px 1px 2px rgba(17, 25, 39, 0.05), 0px 0px 0px 1px #fee4e2;
}

.link-gray {
  @apply text-gray-700 hover:text-gray-900 active:text-gray-900 disabled:text-gray-300;
}

.link-color {
  @apply text-amplify-green-700 hover:text-amplify-green-800 active:text-amplify-green-800 disabled:text-gray-300;
}

.link-destructive {
  @apply text-red-700 hover:text-red-800 active:text-red-800 disabled:text-red-300;
}

.pad-sm {
  @apply py-[7px] px-3.5;
}

.pad-sm-icon {
  @apply px-2 py-[7px];
}

.pad-md {
  @apply py-[9px] px-4;
}

.pad-md-icon {
  @apply px-[9px] py-[9px];
}

.title-sm {
  @apply text-sm leading-5;
}

.title-md {
  @apply text-base leading-6;
}

.button-sm {
  @apply font-sans text-sm font-medium tracking-normal leading-5;
}

.button-full-width {
  @apply w-full flex justify-between;
}
