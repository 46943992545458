.text-display-xl {
  @apply font-serif text-3xl font-normal tracking-normal leading-9 sm:text-5xl sm:leading-[60px];
}

.text-display-lg {
  @apply font-serif text-2xl font-normal tracking-normal leading-8 sm:text-4xl sm:leading-10;
}

.text-display-md {
  @apply font-serif text-2xl font-normal tracking-normal leading-8 sm:text-3xl sm:leading-9;
}

.text-display-sm {
  @apply font-serif text-2xl font-normal tracking-normal leading-8;
}

.text-heading-xl {
  @apply font-sans text-4xl font-semibold tracking-normal leading-[54px];
}

.text-heading-lg {
  @apply font-sans text-2xl font-semibold tracking-normal leading-8;
}

.text-heading-md {
  @apply font-sans text-xl font-semibold tracking-normal leading-7;
}

.text-heading-sm {
  @apply font-sans text-lg font-semibold tracking-normal leading-7;
}

.text-subheading {
  @apply font-sans text-sm font-semibold leading-5 tracking-wider;
}

.text-label-lg {
  @apply font-sans text-lg font-semibold tracking-normal leading-7;
}

.text-label-md {
  @apply font-sans text-base font-semibold tracking-normal leading-6;
}

.text-label-sm {
  @apply font-sans text-sm font-semibold tracking-normal leading-5;
}

.text-body-lg {
  @apply font-sans text-lg font-normal tracking-normal leading-7;
}

.text-body-md {
  @apply font-sans text-base font-normal tracking-normal leading-6;
}

.text-body-sm {
  @apply font-sans text-sm font-normal tracking-normal leading-5;
}

.text-button-md {
  @apply font-sans text-base font-medium tracking-normal leading-6;
}

.text-button-sm {
  @apply font-sans text-sm font-medium tracking-normal leading-5;
}

.text-caption-bold {
  @apply font-sans text-xs font-semibold tracking-normal leading-4;
}

.text-caption {
  @apply font-sans text-xs tracking-normal leading-4 font-medium;
}

.text-hyperlink-lg {
  @apply font-sans text-lg font-normal tracking-normal leading-7 underline;
}

.text-hyperlink-md {
  @apply font-sans text-base font-normal tracking-normal leading-6 underline;
}

.text-hyperlink-sm {
  @apply font-sans text-sm font-normal tracking-normal leading-5 underline;
}

.text-hyperlink-caption {
  @apply font-sans text-xs font-normal tracking-normal leading-4 underline;
}
