/* Overrides for the react-fontpicker-ts library */
.fontpicker,
.fontpicker * {
}

.fontpicker {
  border-radius: 3px !important;
  background: white !important;
  border: 1px solid #e5e5e5 !important;
  padding: 8px !important;
}

.fontpicker__preview {
  border: none !important;
  border-radius: 0.375rem !important;
  background-color: white !important;
  filter: none !important;
  color: rgb(108 115 127 / var(--tw-text-opacity)) !important;
  padding: 8px 2.5rem 0.5rem 1rem !important;
  font-size: 1rem !important;
  box-shadow: none !important;
}

.fontpicker__preview:focus {
  border-color: #6b7280 !important;
}

.fontpicker__search {
  background-color: white !important;
  color: black !important;
}

.fontpicker__search:focus {
}

.fontpicker__popout {
  max-height: 15rem !important;
  background: white !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 0.375rem !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.fontpicker__popout.fontpicker__active {
}

.fontpicker__option {
  color: black !important;
  background-color: white !important;
}

.fontpicker__option:hover,
.fontpicker__option.selected {
  background-color: #eee !important;
}

.fontpicker__nomatches {
  color: #d1d5db !important;
}
