.color-input {
  @apply rounded border h-11 px-2 items-center gap-2 flex bg-white box-border;
}
.color-input input[type='color'] {
  @apply rounded w-[30px] h-[32px] inline-block relative shrink-0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.color-swatch {
  @apply rounded w-6 h-6 inline-block border border-gray-100;
}

.color-input input[type='color']::-webkit-color-swatch {
  @apply color-swatch;
}

.color-input input[type='color']::-moz-color-swatch {
  @apply color-swatch;
}

.color-input > label {
  @apply inline-block text-gray-500 shrink-0 min-w-[80px] cursor-pointer;
}

.color-input > input[type='text'] {
  @apply inline-block text-gray-500 shrink-0 w-full max-w-[calc(100%-42px)] box-border border-none active:outline-none focus:outline-none focus:border-none focus:ring-0 px-0 focus:text-gray-700 transition-colors;
}
