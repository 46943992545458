.default {
  @apply bg-white rounded-lg;
}

.default-bordered {
  @apply bg-white rounded-lg border border-y-gray-200;
}

.disabled {
  @apply bg-gray-50 rounded-lg;
}

.disabled-bordered {
  @apply bg-gray-50 rounded-lg border border-gray-200;
}

.selected {
  @apply rounded-lg border-2 hover:shadow-md transition duration-200 ease-linear bg-amplify-green-25 hover:bg-amplify-green-50 border-amplify-green-600;
}

.transparent {
  @apply bg-transparent;
}
